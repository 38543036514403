import { first, isNumber, isUndefined, last } from "lodash";
import dayjs, { type Dayjs } from "dayjs";

export type DateLike = string | number | Dayjs | Date;

export const now = () => dayjs();

export const parseDateLike = (time: DateLike) => {
  return isNumber(time) ? dayjs.unix(time) : dayjs(time);
};

export function isExpired(time: string | number): boolean {
  if (!isUndefined(time)) {
    return parseDateLike(time).isBefore(dayjs());
  }
  return true;
}

export const formatDate = (
  year?: string | number,
  month?: number,
  day?: number
) => {
  if (year && month && day) {
    const m = String(month).padStart(2, "0");
    const d = String(day).padStart(2, "0");
    return `${year}-${m}-${d}`;
  }
};

export const formatTimePeriod = (
  o: Partial<{
    start: DateLike;
    end: DateLike;
    startOnly: boolean;
    hideStart: boolean;
    hideEnd: boolean;
  }>
) => {
  const startDate =
    !o.hideStart && o.start ? parseDateLike(o.start) : undefined;
  const endDate =
    !o.hideEnd && o.end && !o.startOnly ? parseDateLike(o.end) : undefined;

  if (!startDate && !endDate) {
    return "No date is provided for this event";
  }

  if (startDate && endDate) {
    const start = startDate.format("ddd., D MMM YYYY, h:mm a");
    let end: string;

    if (startDate.isSame(endDate, "day")) {
      end = endDate.format("h:mm a Z");
    } else if (startDate.isSame(endDate, "week")) {
      end = endDate.format("D h:mm a Z");
    } else if (startDate.isSame(endDate, "month")) {
      end = endDate.format("D MMM, h:mm a Z");
    } else {
      end = endDate.format("D MMM YYYY, h:mm a Z");
    }

    return start + " — " + end;
  }

  if (startDate) {
    return startDate.format("ddd., D MMM YYYY, [at] h:mm a Z");
  }

  if (endDate) {
    return "Ends " + endDate.format("ddd., D MMM YYYY, h:mm a Z");
  }

  return "—";
};

export const findRelevantDate = <T extends string | Dayjs>(dates: T[]) => {
  const n = now();
  let earliestFutureDate: Dayjs | undefined;
  let latestPastDate: Dayjs | undefined;

  dates.forEach((d) => {
    const date = dayjs(d);

    if (date.isAfter(n)) {
      if (!earliestFutureDate || date.isBefore(earliestFutureDate)) {
        earliestFutureDate = date;
      }
    } else {
      if (!latestPastDate || date.isAfter(latestPastDate)) {
        latestPastDate = date;
      }
    }
  });

  return earliestFutureDate ? earliestFutureDate : latestPastDate;
};

export const earliestDate = <T extends string | Dayjs>(dates: T[]) => {
  const dates_ = dates.map((d) => dayjs(d));

  let earliest: Dayjs | undefined = first(dates_);

  dates_.forEach((date) => {
    if (date.isBefore(earliest)) {
      earliest = date;
    }
  });

  return earliest;
};

export const latestDate = <T extends string | Dayjs>(dates: T[]) => {
  const dates_ = dates.map((d) => dayjs(d));

  let latest: Dayjs | undefined = last(dates_);

  dates_.forEach((date) => {
    if (date.isBefore(latest)) {
      latest = date;
    }
  });

  return latest;
};
